import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthEvent, OAuthService } from 'angular-oauth2-oidc';
import {BehaviorSubject, from, Observable, of} from 'rxjs';
import {map, switchMap, take, tap} from 'rxjs/operators';
import { ENV_CONFIG } from '../app-config/app-config.service';

import { IAuthService } from 'src/app/types/user/auth-service.model';
import {
  EmailCommonCategory,
  ContactType,
} from 'src/app/types/api/new-data-contracts';
import { FsxContact, JwtUser } from 'src/app/types/user/User';
import { EnvConfig } from 'src/app/types/environments';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export type DoesAccessTokenExist = boolean;
type hasSessionUserBeenSet = boolean;

@Injectable({
  providedIn: 'root',
})
export class AuthService implements IAuthService {
  private sessionUser$: BehaviorSubject<FsxContact | null> =
    new BehaviorSubject<FsxContact | null>(null);
  private isAuthenticated$ = new BehaviorSubject<boolean>(false);
  // Intended for if a user is timed out/logs in and attempted to reach a route prior,
  // this will redirect them to the route they were attempting to reach, set by one of the guards
  public redirectUrl!: string;

  public get isAuthenticated(): Observable<boolean> {
    return of(this.oauthService.hasValidAccessToken());
  }

  public get sessionUser(): Observable<FsxContact | null> {
    return this.sessionUser$.asObservable();
  }

  public get accessToken(): string {
    return this.oauthService.getAccessToken();
  }

  public constructor(
    @Inject(ENV_CONFIG) private readonly envConfig: Observable<EnvConfig>,
    private readonly oauthService: OAuthService,
    private readonly httpClient: HttpClient,
    private readonly router: Router, // private readonly applicationInsightsService: ApplicationInsightsService
  ) {
    this.setSessionStorageListener();
    this.setOAuthServiceEventListener();
    this.oauthService.setupAutomaticSilentRefresh();
    const hasValidAccessToken = this.oauthService.hasValidAccessToken();
    this.isAuthenticated$.next(hasValidAccessToken);
    if (hasValidAccessToken && this.sessionUser$.value === null) {
      this.loadUserProfile();
    }
  }

  public async logout(): Promise<void> {
    // ToDo: This is not working as expected.  Need to figure out how to revoke the token and logout for FSLA sake.
    // this.oauthService.revokeTokenAndLogout().then(() => {
    //   this.sessionUser$.complete();
    //   this.resetUser();
    //   this.unsetUserMetrics();
    // }).catch(e => console.error(e));
    // ----- Part of b2c flow now ------
    
    this.oauthService.logOut();
  }

  public resetUser(): void {
    this.sessionUser$.next(null);
  }

  public login(targetUrl: string = this.router.url): void {
    // this.window.sessionStorage.setItem('current_uri', targetUrl);
    this.envConfig
      .pipe(
        tap(config => {
          // this.oauthService.configure(
          //   authCodeFlowConfig(config.IdentityServer.BaseURL),
          // );
        }),
        take(1),
      )
      .subscribe();

    from(this.oauthService.loadDiscoveryDocument())
      .pipe(
        switchMap(() =>
          from(
            this.oauthService.tryLogin({
              onTokenReceived: _info => this.router.navigate([targetUrl]),
            }),
          ),
        ),
        take(1),
      )
      .subscribe();
  }

  public stopSessionChecks(): void {
    this.oauthService.sessionChecksEnabled = false;
  }

  private setUserForTracking(user: FsxContact) {
    // this.applicationInsightsService.setUserId(user.id ?? '');
  }

  private unsetUserMetrics() {
    // this.applicationInsightsService.clearUserId();
  }

  private setSessionUserFromAccessToken(
    userProfileFromIdApi?: JwtUser,
  ): Observable<hasSessionUserBeenSet> {
    const sessionUser: JwtUser | undefined = userProfileFromIdApi;
    // const idClaims = this.oauthService.getIdentityClaims();
    if (!sessionUser) {
      return of(false);
    }
    const contact: FsxContact = {
      id: sessionUser.sub,
      emails: [
        {
          address: sessionUser.email,
          caption: '',
          category: {
            caption: '',
            commonCategory: EmailCommonCategory.None,
            name: '',
          },
        },
      ],
      actor: {
        name: {
          fullName: sessionUser.name[0],
          givenName: sessionUser.given_name,
          middleName: sessionUser.middle_name,
          surName: sessionUser.family_name,
        },
      },
      organization: {
        primaryContact: {
          id: sessionUser.fsx_organization_id,
          caption: '',
          contactType: ContactType.Organization,
        },
        title: sessionUser.fsx_organization,
        caption: '',
      },
      type: sessionUser.fsx_user_type,
      group: sessionUser.group_id
    };

    this.setUserForTracking(contact);
    this.sessionUser$.next(contact);
    return of(true);
  }

  private navigateToLoginPage(): void {
    // this.window.sessionStorage.setItem('current_uri', this.router.url);
    this.envConfig
      .pipe(
        // tap((envConfig) =>
        //   this.window.open(
        //     authCodeFlowConfig(envConfig.IdentityServer.BaseURL).loginUrl,
        //     '_self'
        //   )
        // ),
        take(1),
      )
      .subscribe();
  }

  private setSessionStorageListener(): void {
    // this.window.addEventListener('storage', (event) => {
    //   // console.warn('check session storage for access key');
    //   if (event.key !== 'access_token' && event.key !== null) {
    //     return;
    //   }
    //   // console.warn('Noticed changes to access_token (most likely from another tab), updating isAuthenticated');
    //   if (!this.oauthService.hasValidAccessToken()) {
    //     // console.warn('access token invalid - directing to login page');
    //     this.navigateToLoginPage();
    //   }
    // });
  }
  private setOAuthServiceEventListener(): void {
    this.oauthService.events
      .pipe(
        map((event: OAuthEvent) => {
          // console.warn('oauth event:\n', event);
          const hasValidAccessToken = this.oauthService.hasValidAccessToken();
          this.isAuthenticated$.next(hasValidAccessToken);
          if (
            ['token_received'].includes(event.type) &&
            this.sessionUser$.value === null
          ) {
            this.loadUserProfile();
          } else if (
            ['session_terminated', 'session_error'].includes(event.type)
          ) {
            return this.navigateToLoginPage();
          }
          else if (event.type === 'token_expires') {
            this.oauthService.silentRefresh();
          }
        }),
      )
      .subscribe();
  }

  private loadUserProfile(): void {
    const userProfile: Promise<{ info: JwtUser }> =
      this.oauthService.loadUserProfile() as Promise<{ info: JwtUser }>;

    from(userProfile)
      .pipe(
        switchMap(({ info }: { info: JwtUser }) =>
          this.setSessionUserFromAccessToken(info),
        ),
        take(1),
      )
      .subscribe();
  }
}
