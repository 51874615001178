/**
 * Stub for Court Houses endpoints.
 */
export interface ICourtHousesEndpoints {
  getAssociateCourtHouses: (courtHouseId: string) => string;
  getCourtHouses: () => string;
  createCourtHouse: () => string;
  getCourtHouseById: (courtHouseId: string) => string;
  updateCourtHouse: (courtHouseId: string) => string;
  deleteCourtHouse: (courtHouseId: string) => string;
  getCourtHouseStates: () => string;
}

/**
 * Court Houses endpoints
 * @param versionedUrl
 */
export const courtHousesEndpoints = (versionedUrl: string): ICourtHousesEndpoints => {
  const baseUrl = `${versionedUrl}court-houses`;

  return {
    getAssociateCourtHouses: (courtHouseId: string) => `${baseUrl}/${courtHouseId}/courts`,
    getCourtHouses: () => `${baseUrl}`,
    createCourtHouse: () => `${baseUrl}`,
    getCourtHouseById: (courtHouseId: string) => `${baseUrl}/${courtHouseId}`,
    updateCourtHouse: (courtHouseId: string) => `${baseUrl}/${courtHouseId}`,
    deleteCourtHouse: (courtHouseId: string) => `${baseUrl}/${courtHouseId}`,
    getCourtHouseStates: () => `${baseUrl}/states`
  }
}
