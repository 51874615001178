import {
  NewUserRequest,
  NewUserResponse, NotificationOptions,
  OrganizationInformation, OrganizationInformationRequest,
  QueuedChangesBatch,
  StateListResponse,
  UserAccesToSaveFilings,
  UserDetailsListResponse,
  UserInfo,
  UserItemListResponse,
  UserPermissions,
  UserRequest, UsersTransferRequest,
  UserTypeItemListResponse,
} from '../../../types/api/dot-net-api-types/data-contracts';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { organizationsEndpoints } from '../../../types/api/endpoints/organizations.endpoints';
import { UtilitiesService } from '../../utilities/utilities-service.service';

export interface IOrganizationsEndpoints {
  getUserTypes(): Observable<UserTypeItemListResponse>;
  getStates(): Observable<StateListResponse>;
  getOrg(orgId: string): Observable<OrganizationInformation>;
  createOrg(orgId: string, params: OrganizationInformationRequest): Observable<object>;
  getJudgeQueues(orgId: string): Observable<UserItemListResponse>;
  getUsers(orgId: string): Observable<UserItemListResponse>;
  getManagementUsers(orgId: string): Observable<UserDetailsListResponse>;
  createUser(orgId: string, params: NewUserRequest): Observable<NewUserResponse>;
  deleteUser(orgId: string, params: UserRequest): Observable<QueuedChangesBatch>;
  transferUser(orgId: string, params: UsersTransferRequest): Observable<QueuedChangesBatch>;
  getUser(orgId: string, userId: string): Observable<UserInfo>;
  updateUser(orgId: string, userId: string, params: UserInfo): Observable<object>;
  getUserPermissions(orgId: string, userId: string): Observable<UserPermissions>;
  updateUserPermissions(orgId: string, userId: string, params: UserPermissions): Observable<object>;
  resetUsersPasswords(orgId: string, params: UserRequest): Observable<object>;
  getTransactionsDraftsPermissions(orgId: string, userId: string): Observable<UserAccesToSaveFilings>;
  updateTransactionsDraftsPermissions(orgId: string, userId: string, params: UserAccesToSaveFilings): Observable<object>;
  getNotificationsInbox(orgId: string, userId: string): Observable<NotificationOptions>;
  updateNotificationsInbox(orgId: string, userId: string, params: NotificationOptions): Observable<object>;
  getNotificationsMessageboard(orgId: string, userId: string): Observable<NotificationOptions>;
  updateNotificationsMessageboard(orgId: string, userId: string, params: NotificationOptions): Observable<object>;
}

/**
 * Base url for the api.
 */
const BASE_URL = environment.ApiServer.BaseURL;

/**
 * Organization endpoints for the api
 */
const ORGANIZATIONS_ENDPOINTS = organizationsEndpoints(BASE_URL);

@Injectable({
  providedIn: 'root'
})
export class OrganizationsApiService implements IOrganizationsEndpoints {

  constructor(private readonly http: HttpClient,
              private readonly utilService: UtilitiesService) {
  }

  /**
   * Endpoint to get user types
   */
  getUserTypes(): Observable<UserTypeItemListResponse> {
    const url = ORGANIZATIONS_ENDPOINTS.getUserTypes();
    return <Observable<UserTypeItemListResponse>>this.http.get(url);
  }

  /**
   * Endpoint to get states
   */
  getStates(): Observable<StateListResponse> {
    const url = ORGANIZATIONS_ENDPOINTS.getStates();
    return <Observable<StateListResponse>>this.http.get(url);
  }

  /**
   * Endpoint to get organization
   * @param orgId
   */
  getOrg(orgId: string): Observable<OrganizationInformation> {
    const url = ORGANIZATIONS_ENDPOINTS.getOrg(orgId);
    return <Observable<OrganizationInformation>>this.http.get(url);
  }

  /**
   * Endpoint to create an organization
   * @param orgId
   * @param params
   */
  createOrg(orgId: string, params: OrganizationInformationRequest): (Observable<object>) {
    const url = this.utilService.buildUrlWithParams(ORGANIZATIONS_ENDPOINTS.createOrg(orgId), params);
    return <Observable<object>>this.http.post(url, {});
  }

  /**
   * Endpoint to get judge queues
   * @param orgId
   */
  getJudgeQueues(orgId: string): Observable<UserItemListResponse> {
    const url = ORGANIZATIONS_ENDPOINTS.getJudgeQueues(orgId);
    return <Observable<UserItemListResponse>>this.http.get(url);
  }

  /**
   * Endpoint to get users
   * @param orgId
   */
  getUsers(orgId: string): Observable<UserItemListResponse> {
    const url = ORGANIZATIONS_ENDPOINTS.getUsers(orgId);
    return <Observable<UserItemListResponse>>this.http.get(url);
  }

  /**
   * Endpoint to get management users
   * @param orgId
   */
  getManagementUsers(orgId: string): Observable<UserDetailsListResponse> {
    const url = ORGANIZATIONS_ENDPOINTS.getManagementUsers(orgId);
    return <Observable<UserDetailsListResponse>>this.http.get(url);
  }

  /**
   * Endpoint to create a user
   * @param orgId
   * @param params
   */
  createUser(orgId: string, params: NewUserRequest): Observable<NewUserResponse> {
    const url = this.utilService.buildUrlWithParams(ORGANIZATIONS_ENDPOINTS.createUser(orgId), params);
    return <Observable<NewUserResponse>>this.http.post(url, {});
  }

  /**
   * Endpoint to delete a user
   * @param orgId
   * @param params
   */
  deleteUser(orgId: string, params: UserRequest): Observable<QueuedChangesBatch> {
    const url = this.utilService.buildUrlWithParams(ORGANIZATIONS_ENDPOINTS.deleteUser(orgId), params);
    return <Observable<QueuedChangesBatch>>this.http.delete(url);
  }

/**
 * Endpoint to transfer a user
 * @param orgId
 * @param params
 */
  transferUser(orgId: string, params: UsersTransferRequest): Observable<QueuedChangesBatch> {
    const url = this.utilService.buildUrlWithParams(ORGANIZATIONS_ENDPOINTS.transferUser(orgId), params);
    return <Observable<QueuedChangesBatch>>this.http.post(url, {});
  }

  /**
   * Endpoint to get a user
   * @param orgId
   * @param userId
   */
  getUser(orgId: string, userId: string): Observable<UserInfo> {
    const url = ORGANIZATIONS_ENDPOINTS.getUser(orgId, userId);
    return <Observable<UserInfo>>this.http.get(url);
  }

  /**
   * Endpoint to update a user
   * @param orgId
   * @param userId
   * @param params
   */
  updateUser(orgId: string, userId: string, params: UserInfo): Observable<object> {
    const url = this.utilService.buildUrlWithParams(ORGANIZATIONS_ENDPOINTS.updateUser(orgId, userId), params);
    return <Observable<object>>this.http.patch(url, {});
  }

  /**
   * Endpoint to get user permissions
   * @param orgId
   * @param userId
   */
  getUserPermissions(orgId: string, userId: string): Observable<UserPermissions> {
    const url = ORGANIZATIONS_ENDPOINTS.getUserPermissions(orgId, userId);
    return <Observable<UserPermissions>>this.http.get(url);
  }

  /**
   * Endpoint to update user permissions
   * @param orgId
   * @param userId
   * @param body
   */
  updateUserPermissions(orgId: string, userId: string, body: UserPermissions): Observable<object> {
    const url = ORGANIZATIONS_ENDPOINTS.updateUserPermissions(orgId, userId);
    return <Observable<object>>this.http.patch(url, body);
  }

  /**
   * Endpoint to reset user passwords
   * @param orgId
   * @param params
   */
  resetUsersPasswords(orgId: string, params: UserRequest): Observable<object> {
    const url = this.utilService.buildUrlWithParams(ORGANIZATIONS_ENDPOINTS.resetUsersPasswords(orgId), params);
    return <Observable<object>>this.http.post(url, {});
  }

  /**
   * Endpoint to get transactions drafts permissions
   * @param orgId
   * @param userId
   */
  getTransactionsDraftsPermissions(orgId: string, userId: string): Observable<UserAccesToSaveFilings> {
    const url = ORGANIZATIONS_ENDPOINTS.getTransactionsDraftsPermissions(orgId, userId);
    return <Observable<UserAccesToSaveFilings>>this.http.get(url);
  }

  /**
   * Endpoint to update transactions drafts permissions
   * @param orgId
   * @param userId
   * @param params
   */
  updateTransactionsDraftsPermissions(orgId: string, userId: string, params: UserAccesToSaveFilings): Observable<object> {
    const url = this.utilService.buildUrlWithParams(ORGANIZATIONS_ENDPOINTS.updateTransactionsDraftsPermissions(orgId, userId), params);
    return <Observable<object>>this.http.patch(url, {});
  }

  /**
   * Endpoint to get notifications inbox
   * @param orgId
   * @param userId
   */
  getNotificationsInbox(orgId: string, userId: string): Observable<NotificationOptions> {
    const url = ORGANIZATIONS_ENDPOINTS.getNotificationsInbox(orgId, userId);
    return <Observable<NotificationOptions>>this.http.get(url);
  }

  /**
   * Endpoint to update notifications inbox
   * @param orgId
   * @param userId
   * @param params
   */
  updateNotificationsInbox(orgId: string, userId: string, params: NotificationOptions): Observable<object> {
    const url = this.utilService.buildUrlWithParams(ORGANIZATIONS_ENDPOINTS.updateNotificationsInbox(orgId, userId), params);
    return <Observable<object>>this.http.patch(url, {});
  }

  /**
   * Endpoint to get notifications messageboard
   * @param orgId
   * @param userId
   */
  getNotificationsMessageboard(orgId: string, userId: string): Observable<NotificationOptions> {
    const url = ORGANIZATIONS_ENDPOINTS.getNotificationsMessageboard(orgId, userId);
    return <Observable<NotificationOptions>>this.http.get(url);
  }

  /**
   * Endpoint to update notifications messageboard
   * @param orgId
   * @param userId
   * @param params
   */
  updateNotificationsMessageboard(orgId: string, userId: string, params: NotificationOptions): Observable<object> {
    const url = this.utilService.buildUrlWithParams(ORGANIZATIONS_ENDPOINTS.updateNotificationsMessageboard(orgId, userId), params);
    return <Observable<object>>this.http.patch(url, {});
  }
}

