import { Component, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import {
  Router,
  RouterOutlet,
} from '@angular/router';
import { AuthService } from './core/auth/auth.service';
import { NavbarComponent } from './components/navbar/navbar.component';
import { Observable, Subject, takeUntil, tap } from 'rxjs';
import { environment } from '../environments/environment';
import { AppConfig } from './core/app-config/app-config.service';
import { FsxContact } from './types/user/User';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { FlexModule } from '@angular/flex-layout';
import {MatSnackBar, MatSnackBarModule} from '@angular/material/snack-bar';
import {TrialBindersStateService} from './trialbinder/services/ui-state/trial-binders-state.service';
import {SnackbarComponent} from './trialbinder/shared/snackbar/snackbar.component';
import { FsxSnackbarComponent, FsxSnackbarTypeEnum } from 'fsx-component-library';
import { GenericStateService } from './services/ui-state/generic-state/generic-state.service';
import { WarningStateService } from './services/ui-state/warning-state/warning-state.service';
import { ErrorsStateService } from './services/ui-state/errors-state/errors-state.service';
import { SuccessStateService } from './services/ui-state/success-state/success-state.service';

export function loadConfig(
  appConfigService: AppConfig,
): () => Observable<string | Error> {
  return () => {
    return appConfigService.load$(environment.CONFIG_FILE);
  };
}
@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [CommonModule, RouterOutlet, NavbarComponent, MatSidenavModule, FlexModule, MatSnackBarModule],
})
export class AppComponent implements OnDestroy {
  public user$: Observable<FsxContact | null> = this.authService.sessionUser;
  private destroy$: Subject<unknown> = new Subject();
  currentDate = new Date();

  private isAuthenticated$: Observable<boolean> =
    this.authService.isAuthenticated.pipe(
      tap((isAuthenticated: boolean) => {
        if (isAuthenticated) {
          this.authService.stopSessionChecks();
        }
      }),
    );

  public constructor(
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    private readonly snackBar: MatSnackBar,
    private readonly authService: AuthService,
    private readonly trialBinderStateService: TrialBindersStateService,
    private readonly errorsStateService: ErrorsStateService,
    private readonly successStateService: SuccessStateService,
    private readonly warningStateService: WarningStateService,
    private readonly genericStateService: GenericStateService,
    private readonly router: Router
  ) {
    // Subscribe to any error messages to show snack bar.
    this.trialBinderStateService.errorMessage$
      .pipe(
        takeUntil(this.destroy$),
        tap(error => {
          this.snackBar.openFromComponent(FsxSnackbarComponent, {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
            data: { message: error, snackType: FsxSnackbarTypeEnum.Error },
            panelClass: [FsxSnackbarTypeEnum.Error],
          });
        }),
      )
      .subscribe()
    ;
    // ToDo: Uncomment this to test snackbar component.
    // this.snackBar.openFromComponent(SnackbarComponent, {
    //   horizontalPosition: 'center',
    //   verticalPosition: 'top',
    //   panelClass: [
    //     'tb-snackbar-error'
    //   ],
    //   data: {
    //     snackbar: this.snackBar,
    //     message: 'An error occurred.',
    //   }
    // });

    // mat icon registry
    // All SVG icons inside the /assets/icons/ folder can be used by file name.
    // e.g., svgIcon="home" for /assets/icons/home.svg
    this.matIconRegistry
      .addSvgIconResolver((name) => this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/icons/${name}.svg`));

    this.isAuthenticated$.pipe(takeUntil(this.destroy$)).subscribe();

    // FsxSnackbar setup
    this.errorsStateService.errorMessage$
      .pipe(
        takeUntil(this.destroy$),
        tap(error => {
          this.snackBar.openFromComponent(FsxSnackbarComponent, {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
            data: { message: error, snackType: FsxSnackbarTypeEnum.Error },
            panelClass: [FsxSnackbarTypeEnum.Error],
          });
        }),
      )
      .subscribe()
    ;

    this.successStateService.successMessage$
      .pipe(
        takeUntil(this.destroy$),
        tap(message => {
          this.snackBar.openFromComponent(FsxSnackbarComponent, {
            duration: 3000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
            data: { message: message, snackType: FsxSnackbarTypeEnum.Success },
            panelClass: [FsxSnackbarTypeEnum.Success],
          });
        }),
      )
      .subscribe()
    ;

    this.warningStateService.warningMessage$
      .pipe(
        takeUntil(this.destroy$),
        tap(message => {
          this.snackBar.openFromComponent(FsxSnackbarComponent, {
            duration: 3000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
            data: { message: message, snackType: FsxSnackbarTypeEnum.Warning },
            panelClass: [FsxSnackbarTypeEnum.Warning],
          });
        }),
      )
      .subscribe()
    ;

    this.genericStateService.genericMessage$
      .pipe(
        takeUntil(this.destroy$),
        tap(message => {
          this.snackBar.openFromComponent(FsxSnackbarComponent, {
            duration: 3000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
            data: { message: message, snackType: FsxSnackbarTypeEnum.Default },
            panelClass: [FsxSnackbarTypeEnum.Default],
          });
        }),
      )
      .subscribe()
    ;

  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
