/**
 * Stub for Organizations endpoints.
 */
export interface IOrganizationsEndpoints {
  getUserTypes: () => string;
  getStates: () => string;
  getOrg: (orgId: string) => string;
  createOrg: (orgId: string) => string;
  getJudgeQueues: (orgId: string) => string;
  getUsers: (orgId: string) => string;
  getManagementUsers: (orgId: string) => string;
  createUser: (orgId: string) => string;
  deleteUser: (orgId: string) => string;
  transferUser: (orgId: string) => string;
  getUser: (orgId: string, userId: string) => string;
  updateUser: (orgId: string, userId: string) => string;
  getUserPermissions: (orgId: string, userId: string) => string;
  updateUserPermissions: (orgId: string, userId: string) => string;
  resetUsersPasswords: (orgId: string) => string;
  getTransactionsDraftsPermissions: (orgId: string, userId: string) => string;
  updateTransactionsDraftsPermissions: (orgId: string, userId: string) => string;
  getNotificationsInbox: (orgId: string, userId: string) => string;
  updateNotificationsInbox: (orgId: string, userId: string) => string;
  getNotificationsMessageboard: (orgId: string, userId: string) => string;
  updateNotificationsMessageboard: (orgId: string, userId: string) => string;
}

/**
 * Organizations endpoints
 * @param versionedUrl
 */
export const organizationsEndpoints = (versionedUrl: string): IOrganizationsEndpoints => {
  const baseUrl = `${versionedUrl}organizations`;

  return {
    getUserTypes: () => `${baseUrl}/user-types`,
    getStates: () => `${baseUrl}/states`,
    getOrg: (orgId: string) => `${baseUrl}/${orgId}`,
    createOrg: (orgId: string) => `${baseUrl}/${orgId}`,
    getJudgeQueues: (orgId: string) => `${baseUrl}/${orgId}/judge-queues`,
    getUsers: (orgId: string) => `${baseUrl}/${orgId}/users/members`,
    getManagementUsers: (orgId: string) => `${baseUrl}/${orgId}/management/users`,
    createUser: (orgId: string) => `${baseUrl}/${orgId}/users`,
    deleteUser: (orgId: string) => `${baseUrl}/${orgId}/users`,
    transferUser: (orgId: string) => `${baseUrl}/${orgId}/users/transfer`,
    getUser: (orgId: string, userId: string) => `${baseUrl}/${orgId}/users/${userId}`,
    updateUser: (orgId: string, userId: string) => `${baseUrl}/${orgId}/users/${userId}`,
    getUserPermissions: (orgId: string, userId: string) => `${baseUrl}/${orgId}/users/${userId}/permissions`,
    updateUserPermissions: (orgId: string, userId: string) => `${baseUrl}/${orgId}/users/${userId}/permissions`,
    resetUsersPasswords: (orgId: string) => `${baseUrl}/${orgId}/users/reset-password`,
    getTransactionsDraftsPermissions: (orgId: string, userId: string) => `${baseUrl}/${orgId}/users/${userId}/transactions/drafts/permissions`,
    updateTransactionsDraftsPermissions: (orgId: string, userId: string) => `${baseUrl}/${orgId}/users/${userId}/transactions/drafts/permissions`,
    getNotificationsInbox: (orgId: string, userId: string) => `${baseUrl}/${orgId}/users/${userId}/notifications/options/inbox`,
    updateNotificationsInbox: (orgId: string, userId: string) => `${baseUrl}/${orgId}/users/${userId}/notifications/options/inbox`,
    getNotificationsMessageboard: (orgId: string, userId: string) => `${baseUrl}/${orgId}/users/${userId}/notifications/options/messageboard`,
    updateNotificationsMessageboard: (orgId: string, userId: string) => `${baseUrl}/${orgId}/users/${userId}/notifications/options/messageboard`
  }
}
