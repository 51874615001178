import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class UtilitiesService {

  /**
   *
   * @param baseUrl
   * @param params
   * @private
   */
  public buildUrlWithParams(baseUrl: string, params: any): string {
    const queryParams = Object.keys(params)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
      .join('&');
    return `${baseUrl}?${queryParams}`;
  }
}
