import { ResolveFn, Router } from '@angular/router';
import { CourtHouseItemListResponse, StateListResponse,
} from '../../types/api/dot-net-api-types/data-contracts';
import { Observable } from 'rxjs';
import { inject } from '@angular/core';
import { CourtHousesApiService } from '../../services/api/court-houses/court-houses-api.services';
import { ErrorsStateService } from '../../services/ui-state/errors-state/errors-state.service';
import { catchError } from 'rxjs/operators';
import { ROUTES } from '../../../routes';

/**
 * Resolver for the court house states.
 */
export const manageCourtHouseStatesResolver: ResolveFn<Observable<StateListResponse | boolean>> = () => {
  /**
   * The court houses api service.
   */
  const courtHousesApiService = inject(CourtHousesApiService);
  /**
   * The errors state service.
   */
  const errorsStateService = inject(ErrorsStateService);
  /**
   * The angular router.
   */
  const router = inject(Router);

  return courtHousesApiService.getCourtHouseStates().pipe(
    catchError(err => {
      console.error('An error occurred while fetching court house states.', err);
      errorsStateService.showError('An error occurred while fetching court house states.');
      return router.navigate([ROUTES.HOME]);
    })
  );
}

export const manageCourtHousesResolver: ResolveFn<Observable<CourtHouseItemListResponse | boolean>> = () => {
  /**
   * The court houses api service.
   */
  const courtHousesApiService = inject(CourtHousesApiService);
  /**
   * The errors state service.
   */
  const errorsStateService = inject(ErrorsStateService);
  /**
   * The angular router.
   */
  const router = inject(Router);

  return courtHousesApiService.getCourtHouses('').pipe(
    catchError(err => {
      console.error('An error occurred while fetching court houses.', err);
      errorsStateService.showError('An error occurred while fetching court houses.');
      return router.navigate([ROUTES.HOME]);
    })
  );
}
