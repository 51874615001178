import {
  CourtHouseResult,
  CourtHouseRequest, CourtHouseItemListResponse, StateListResponse, CourtItemListResponse,
} from '../../../types/api/dot-net-api-types/data-contracts';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { courtHousesEndpoints } from '../../../types/api/endpoints/court-houses.endpoints';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilitiesService } from 'fsx-component-library';

export interface ICourtHousesApiService {
  getAssociateCourtHouses(courtHouseId: string): Observable<CourtItemListResponse>;
  getCourtHouses(params: string): Observable<CourtHouseItemListResponse>;
  createCourtHouse(courtHouse: CourtHouseRequest): Observable<string>;
  getCourtHouseById(courtHouseId: string): Observable<CourtHouseResult>;
  updateCourtHouse(courtHouseId: string, params: CourtHouseRequest): Observable<object>;
  deleteCourtHouse(courtHouseId: string): Observable<string>;
  getCourtHouseStates(): Observable<StateListResponse>;
}
/**
 * Base url for the api.
 */
const BASE_URL = environment.ApiServer.BaseURL;

/**
 * Court House Endpoints for the api.
 */
const COURT_HOUSE_ENDPOINTS = courtHousesEndpoints(BASE_URL);

@Injectable({
  providedIn: 'root'
})
export class CourtHousesApiService implements ICourtHousesApiService {

  constructor(private readonly http: HttpClient) {
  }

  /**
   * Endpoint to get associate court houses
   * @param courtHouseId
   */
  getAssociateCourtHouses(courtHouseId: string): Observable<CourtItemListResponse> {
    const url = COURT_HOUSE_ENDPOINTS.getAssociateCourtHouses(courtHouseId);
    return <Observable<CourtItemListResponse>>this.http.get(url);
  }

  /**
   * Endpoint to get court houses by state code
   * @param stateCode
   */
  getCourtHouses(stateCode: string): Observable<CourtHouseItemListResponse> {
    const url = COURT_HOUSE_ENDPOINTS.getCourtHouses();
    return <Observable<CourtHouseItemListResponse>>this.http.get(url, { params: { stateCode } });
  }

  /**
   * Endpoint to create a court house
   * @param courtHouse
   */
  createCourtHouse(courtHouse: CourtHouseRequest): Observable<string> {
    const url = COURT_HOUSE_ENDPOINTS.createCourtHouse();
    return <Observable<string>>this.http.post(url, courtHouse);
  }

  /**
   * Endpoint to get a court house by id
   * @param courtHouseId
   */
  getCourtHouseById(courtHouseId: string): Observable<CourtHouseResult> {
    const url = COURT_HOUSE_ENDPOINTS.getCourtHouseById(courtHouseId);
    return <Observable<CourtHouseResult>>this.http.get(url);
  }

  /**
   * Endpoint to update a court house
   * @param courtHouseId
   * @param params
   */
  updateCourtHouse(courtHouseId: string, params: CourtHouseRequest): Observable<CourtHouseRequest> {
    const url = COURT_HOUSE_ENDPOINTS.updateCourtHouse(courtHouseId); // Use the base endpoint
    return <Observable<CourtHouseRequest>>this.http.put(url, params);
  }

  /**
   * Endpoint to delete a court house
   * @param courtHouseId
   */
  deleteCourtHouse(courtHouseId: string): Observable<string> {
    const url = COURT_HOUSE_ENDPOINTS.deleteCourtHouse(courtHouseId);
    return <Observable<string>>this.http.delete(url);
  }

  /**
   * Endpoint to get court house states
   */
  getCourtHouseStates(): Observable<StateListResponse> {
    const url = COURT_HOUSE_ENDPOINTS.getCourtHouseStates();
    return <Observable<StateListResponse>>this.http.get(url);
  }
}
