import { ResolveFn, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserItemListResponse } from '../../types/api/dot-net-api-types/data-contracts';
import { inject } from '@angular/core';
import { OrganizationsApiService } from '../../services/api/organizations/organizations-api.services';
import { ErrorsStateService } from '../../services/ui-state/errors-state/errors-state.service';
import { catchError } from 'rxjs/operators';
import { ROUTES } from '../../../routes';
import { OAuthService } from 'angular-oauth2-oidc';

/**
 * Resolver to get organization users
 */
export const organizationUsersResolver: ResolveFn<Observable<UserItemListResponse | boolean>> = (route) => {
  /**
   * Inject OAuthService to get user's org id
   */
  const oAuthService = inject(OAuthService);

  /**
   * The organizations api service.
   */
  const organizationsApiService = inject(OrganizationsApiService);
  /**
   * The errors state service.
   */
  const errorsStateService = inject(ErrorsStateService);
  /**
   * The angular router.
   */
  const router = inject(Router);

  /**
   * Get the org id from the user's claims
   */
  const sessionUser = oAuthService.getIdentityClaims();
  const orgId = sessionUser['fsx_organization_id'];

  return organizationsApiService.getUsers(orgId).pipe(
    catchError(err => {
      console.error('An error occurred while fetching organization users.', err);
      errorsStateService.showError('An error occurred while fetching organization users.');
      return router.navigate([ROUTES.HOME]);
    })
  );
}
