import { Routes } from '@angular/router';
import {
  manageCourtHousesResolver,
  manageCourtHouseStatesResolver,
} from './app/pages/manage-court-house/manage-court-house.resolver';
import { canDeactivateGuard } from './app/core/guards/can-deactivate.guard';
import { organizationUsersResolver } from './app/pages/preferences/preferences.resolver';

export enum ROUTES {
  HOME = 'home',
  CLERK_REVIEW_LIST = 'clerk-review-list',
  CLERK_REVIEW = 'clerk-review',
  FILING = 'filing',
  CASE_MANAGEMENT = 'case-management',
  TRIALBINDER = 'trialbinder',
  MESSAGEBOARD = 'messageboard',
  PREFERENCES = 'preferences',
  MY_INFORMATION = 'my-information',
  NOTIFICATION_OPTIONS = 'notification-options',
  SAVED_TRANSACTIONS = 'saved-transactions',
  MANAGE_COURT_HOUSE = 'manage-court-house',
  LOGOUT = 'logout'
}

export enum ROUTER_OUTLETS {
  SIDEPANEL_INFO = 'sidepanel-info',
}

export const APP_ROUTES: Routes = [
  {
    path: '',
    // ToDo: Disabled until I can correctly get the redirect to work.
    // canActivateChild: [canActivateChild],
    children: [
      {
        path: ROUTES.NOTIFICATION_OPTIONS,
        title: 'Notification Options',
        loadComponent: () => import('./app/pages/notification-options/notification-options.component').then(m => m.NotificationOptionsComponent),
        outlet: ROUTER_OUTLETS.SIDEPANEL_INFO,
      },
      {
        path: ROUTES.MY_INFORMATION,
        title: 'My Information',
        loadComponent: () => import('./app/pages/my-information/my-information.component').then(m => m.MyInformationComponent),
        outlet: ROUTER_OUTLETS.SIDEPANEL_INFO,
      },
      {
        path: ROUTES.SAVED_TRANSACTIONS,
        title: 'My Saved Transactions',
        loadComponent: () => import('./app/pages/saved-transactions/saved-transactions.component').then(m => m.SavedTransactionsComponent),
        outlet: ROUTER_OUTLETS.SIDEPANEL_INFO,
      },
      {
        path: ROUTES.HOME,
        title: 'Home',
        loadComponent: () => import('./app/pages/home/home.component'),
        data: {
          breadcrumb: 'Home'
        }
      },
      {
        path: ROUTES.CLERK_REVIEW_LIST,
        title: 'Clerk Review',
        loadComponent: () => import('./app/pages/clerk-review-list/clerk-review-list.component'),
        data: {
          breadcrumb: 'Clerk Review'
        }
      },
      {
        path: `${ROUTES.CLERK_REVIEW}/:filingId`,
        title: 'Clerk Review',
        loadComponent: () => import('./app/pages/clerk-review/clerk-review.component'),
        data: {
          breadcrumb: 'Clerk Review'
        }
      },
      {
        path: ROUTES.FILING,
        loadComponent: () => import('./app/pages/filing/filing.component'),
        data: {
          breadcrumb: 'Filing'
        }
      },
      {
        path: ROUTES.CASE_MANAGEMENT,
        title: 'Case & Party Management',
        data: {
          breadcrumb: 'Case & Party Management'
        },
        loadChildren: () => import('./app/pages/track-and-manage/case-management/case-management.routes'),
      },
      {
        path: ROUTES.TRIALBINDER,
        title: 'eBinder',
        loadChildren: () => import('./app/trialbinder/trialbinder.routes'),
      },
      {
        path: ROUTES.MESSAGEBOARD,
        title: 'Message Boards',
        data: {
          breadcrumb: 'Message Boards'
        },
        children: [
          {
            path: '',
            loadComponent: () => import('./app/pages/messageboard/messageboard.component'),
            pathMatch: 'full',
            data: {
              breadcrumb: ''
            },
          },
          {
            path: 'subjects/:forumID',
            loadComponent: () => import('./app/pages/subjects/subjects.component'),
            data: {
              breadcrumb: 'Subjects'
            },
          },
        ],
      },
      {
        path: ROUTES.PREFERENCES,
        title: 'Preferences',
        loadChildren: () => import('./app/pages/preferences/preferences.routes'),
        resolve: {
          organizationUsers: organizationUsersResolver
        }
      },
      {
        path: ROUTES.MANAGE_COURT_HOUSE,
        title: 'Manage Court House',
        loadComponent: () => import('./app/pages/manage-court-house/manage-court-house.component'),
        data: {
          breadcrumb: 'Manage Court House',
        },
        resolve: {
          courtHouseStates: manageCourtHouseStatesResolver,
          courtHouses: manageCourtHousesResolver
        },
        canDeactivate: [canDeactivateGuard],
      },
      {
        path: ROUTES.LOGOUT,
        title: '',
        loadComponent: () => import('./app/pages/logout/logout.component'),
        data: {
          breadcrumb: ''
        }
      },
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: '**', redirectTo: 'home', pathMatch: 'full' },
    ]
  }
]
