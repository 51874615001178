<section class="navbar">
  <ul>
    <li class="navbar__link">      
      <i class="fa-sharp fa-solid fa-house fa-icon" 
        [routerLink]="'home'" 
        [routerLinkActive]="['navbar__link-active', 'fa-regular']"
        matTooltip="Home" 
        matTooltipClass="navbar__tooltip"
        matTooltipPosition="right"
        (click)="resetTrialBinders()"
      >
      </i>    
    </li>
    <!--
    <li class="navbar__link">      
      <i class="fa-sharp fa-solid fa-cabinet-filing fa-icon" [routerLink]="'filing'" matTooltip="FSLA" matTooltipClass="navbar__tooltip" matTooltipPosition="right"></i>    
    </li>
    -->
    <li class="navbar__link">      
      <i class="fa-sharp fa-solid fa-folder-open fa-icon" 
        [routerLink]="'trialbinder'" 
        [routerLinkActive]="['navbar__link-active', 'fa-regular']"
        matTooltip="eBinder" 
        matTooltipClass="navbar__tooltip" 
        matTooltipPosition="right"
        (click)="resetTrialBinders()"
      ></i>    
    </li>
     <!--
    <li class="navbar__link">      
      <i class="fa-sharp fa-solid fa-bell fa-icon" [routerLink]="'alert'" matTooltip="Alerts" matTooltipClass="navbar__tooltip" matTooltipPosition="right"></i>    
    </li>
    -->
     <!--
    <li class="navbar__link">      
      <i class="fa-sharp fa-solid magnifying-glass fa-icon" [routerLink]="'messageboard'" matTooltip="Message Board" matTooltipClass="navbar__tooltip" matTooltipPosition="right"></i>    
    </li>
    -->
    <li class="navbar__link">      
      <i class="fa-sharp fa-solid fa-messages fa-icon" 
        [routerLink]="'messageboard'" 
        matTooltip="Message Board" 
        matTooltipClass="navbar__tooltip" 
        matTooltipPosition="right"
        [routerLinkActive]="['navbar__link-active', 'fa-regular']"
        (click)="resetTrialBinders()"
      ></i>    
    </li>
    <li class="navbar__link">      
      <i class="fa-solid fa-gavel fa-icon" [matMenuTriggerFor]="reviewMenu" matTooltip="Review Items" matTooltipClass="navbar__tooltip" matTooltipPosition="right"></i>
      <mat-menu #reviewMenu="matMenu" xPosition="after" class="nav-submenu">
        <button mat-menu-item [routerLink]="'clerk-review-list'"><a>Clerk Review</a></button>
        <button mat-menu-item><a>Judge Review</a></button>
      </mat-menu>
    </li>
    <li class="navbar__link">
      <mat-icon
      class="fa-icon fa-sharp fa-solid fa-address-book"
      matTooltip="Contact Us"
      matTooltipPosition="right"
      matTooltipClass="navbar__tooltip" 
      [matMenuTriggerFor]="contactMenu"/>
      <mat-menu #contactMenu="matMenu" xPosition="after" class="nav-submenu">
        <button mat-menu-item><a id="LnkChatOnline" href="javascript:void zE('messenger', 'open');">Chat Online</a></button>
        <button mat-menu-item><a href="tel:1-888-529-7587">1-888-529-7587</a></button>
      </mat-menu>
    </li>
    <!--<li class="navbar__link">
      <app-auto-open-menu>
        <mat-icon trigger class="navbar__link-icon" svgIcon="settings" />
        <button content (click)="logout()">Log out</button>
      </app-auto-open-menu>
    </li>-->

  </ul>
  <!--<a href="tel:1-555-555-5555" class="navbar__link">
    <mat-icon
      class="navbar__link-icon || phone-icon"
      svgIcon="contact-phone"
      matTooltip="Contact Us"
      matTooltipPosition="right"
      matTooltipClass="navbar__tooltip"
    />
  </a>-->
  <section class="bottom-links">
    <a class="navbar__link"
       [routerLink]="['/preferences']"
       routerLinkActive="navbar__link-active">
      <mat-icon
        class="navbar__link-icon"
        svgIcon="settings"
        matTooltip="Preferences"
        matTooltipPosition="right"
        matTooltipClass="navbar__tooltip"
      />
    </a>
    <a class="navbar--logo" [routerLink]="['/home']">
      <img
        alt="File and ServeXpress logo"
        src="../../../assets/icons/FSX-logo.png"
        style="width: 28px"
      />
    </a>
  </section>
</section>
