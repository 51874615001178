import { HttpClient } from '@angular/common/http';
import { Injectable, InjectionToken } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import {BehaviorSubject, Observable, of} from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import {authCodeFlowConfig, FsxB2CDocumentDiscoveryURL} from '../auth/auth-config';
import { ENVIRONMENT, EnvConfig } from 'src/app/types/environments';
import { FSXEndpoints } from '../../types/api/endpoints/endpoints.model';
import { fsxApi } from '../../types/api/endpoints/fsx-api-endpoints';

export const ENV_CONFIG = new InjectionToken<Observable<EnvConfig>>('envConfig');
export const ENV_CONFIG_VALUE = new InjectionToken<EnvConfig>('envConfigValue');

interface IAppConfig {
  envConfig: Observable<EnvConfig>;

  load$(configName?: ENVIRONMENT): Observable<string | Error>;
}

@Injectable({
  providedIn: 'root',
})
export class AppConfig implements IAppConfig {
  public fsxApi!: FSXEndpoints;
  private _envConfigAsObj!: EnvConfig;
  private envConfig$: BehaviorSubject<EnvConfig> =
    new BehaviorSubject<EnvConfig>({
      ApiServer: { BaseURL: '' },
      Environment: {},
      API_VERSION: 'v1',
    } as EnvConfig);

  public get envConfig(): Observable<EnvConfig> {
    return this.envConfig$.asObservable();
  }

  public get envConfigAsObj(): EnvConfig {
    return this._envConfigAsObj;
  }

  public constructor(
    private readonly http: HttpClient,
    private readonly oauthService: OAuthService,
    private readonly router: Router,
  ) {}

  public load$(configName?: ENVIRONMENT): Observable<string | Error> {
    //const configFileName = configName === ENVIRONMENT.DEV ? `config.dev.json` : 'config.json';

    let configFileName = '';

    switch(configName)
      {
        case ENVIRONMENT.DEV:
          configFileName = 'config.dev.json';
          break;
        case ENVIRONMENT.UAT:
          configFileName = 'config.uat.json';
          break;
        case ENVIRONMENT.PROD:
          configFileName = 'config.prod.json';
          break;
        default:
          configFileName = 'config.json';
          break;
      }

    return this.http.get<EnvConfig>(`./assets/config/${configFileName}`).pipe(
      switchMap((configFile: EnvConfig) =>
        this.setEnvConfig(configFile, configName ?? ENVIRONMENT.PROD),
      ),
      switchMap((configFile: EnvConfig) => {
        return this.setupAuth(configFile);
      }),
      map((r) => {
        return 'success';
      }),
      catchError((err: Error) => {
        console.error('Error bootstrapping app:\n', err);
        return of(err);
      }),
      take(1),
    );
  }

  private setEnvConfig(
    configFile: EnvConfig,
    configName: ENVIRONMENT,
  ): Observable<EnvConfig> {
    const envConfig: EnvConfig = {
      ApiServer: {
        BaseURL: configFile.ApiServer.BaseURL,
      },
      API_VERSION: 'v1',
      CONFIG_FILE: configName,
      Environment: {
        Env: configFile.Environment?.Env
          ? configFile.Environment?.Env
          : configName,
      },
      IdentityServer: {
        BaseURL: configFile.IdentityServer.BaseURL,
      },
      iframeUrl: configFile.iframeUrl,
      iframeLogoutUrl: configFile.iframeLogoutUrl,
      filingUrl: configFile.filingUrl,
      MAX_FILE_SIZE: configFile.MAX_FILE_SIZE,
      production: configName === ENVIRONMENT.PROD,
    };

    this.fsxApi = fsxApi(envConfig.ApiServer.BaseURL);
    this._envConfigAsObj = envConfig;
    this.envConfig$.next(envConfig);
    return of(envConfig);
  }

  private setupLogging(envConfig: EnvConfig): void {
    // this.applicationInsightsService.loadAppInsights(
    //     envConfig.ApplicationInsights?.InstrumentationKey ?? {}
    // );
    // this.perfumePerfMonitoringService.initPerfume();
  }

  private setupAuth(configFile: EnvConfig): Promise<void> {
    // ToDO: FOr reference - part of the old flow using Identity.
    // if (!navigator.onLine) {
    //   return of(false);
    // }
    // this.oauthService.setStorage(sessionStorage);
    // this.oauthService.configure(
    //   authCodeFlowConfig(configFile.IdentityServer.BaseURL),
    // );
    //
    // return from(
    //   this.oauthService.loadDiscoveryDocumentAndLogin({
    //     onTokenReceived: info => {
    //       this.router.navigate([info.state]);
    //     },
    //   }),
    // );
    // ---------- Start of using B2C -----------
    this.oauthService.setStorage(sessionStorage);
    this.oauthService.configure(
      authCodeFlowConfig(configFile.IdentityServer.BaseURL),
    );

    return this.oauthService
      .loadDiscoveryDocument(FsxB2CDocumentDiscoveryURL)
      .then((_) => {
        return this.oauthService.tryLogin();

      })
      // Check if we have a valid access token. If not, do something.
      .then((_) => {
        if (!this.oauthService.hasValidAccessToken()) {
          return this.oauthService.initLoginFlow();
        }
      })
      // If any error occurs, catch it to prevent the app from crashing, and init code flow.
      .catch((err) => {
        console.error('Error loading discovery document: ', err);
        this.oauthService.initCodeFlow();
      })
      ;
  }
  public getIdentityClaim(claim: IIdentityClaim, log = false): string {
    const claims = this.oauthService.getIdentityClaims();
    if (log) console.log(claims);
    if (!claims) return '';
    return claims[claim];
  }
}

export enum IIdentityClaim {
  NeedsRegistration = 'extension_NeedsRegistration',
  RegistrationPending = 'extension_RegistrationPending',
  BlockText = 'extension_BlockText',
  AgencyUserID = 'extension_AgencyUserID',
  Issuer = 'iss',
}
